header {
    position: relative;
}

.header {
    padding: 30px 0 10px;

    &__container {
        @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
        gap: 45px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -30px;
            left: 16px;
            width: 2px;
            height: 84px;
            background-color: $color-main;
            z-index: -1;
        }
    }

    @keyframes headerFixed {
        from {
            top: -200px;
        }
        to {
            top: 0;
        }
    }

    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 120px;
        z-index: 10;
        background-color: $color-white;
        animation: .5s headerFixed;
        box-shadow: 1px 1px 20px 0px #2828281A;
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(220px, 80px);
    margin: 0 auto 0 30px;

    &__title {
        @include size(100%);
        
        svg {
            fill: $color-dark;
            @include size(100%);
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
.tools {
    

    &__container {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        pointer-events: auto;
        gap: 10px;
    }

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(40px);
        margin: 0;
        padding: 0;
        background-color: $color-fourth;
        border: $border-width $border-style $color-fourth;
        border-radius: $border-radius--round;
        cursor: pointer;

        svg {
            @include size(40px);
            fill: $color-white;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            svg {
                fill: $color-dark;
            }
        }

        &--search {
            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        &__container {
            gap: 25px;

            &::before {
                height: 70px;
                left: 10px;
            }
        }
    }

    .identity {
        @include size(160px, 60px);
        margin-left: 20px;
    }
}


// 960
@media screen and (max-width: $medium) {

    .header {
        &__container {
            @include flex($direction: row, $alignItems: center, $justifyContent: center);

            &::before {
                display: none;
            }
        }
    }

    // Identity
    .identity {
        @include size(200px, 75px);
        margin: 0;
    }

    // Tools
    .tools {
        position: fixed;        
        bottom: 10px;
        right: calc(5% + 10px);
        z-index: 11;

        &__container {
            display: flex;
            justify-content: flex-end;
            position: relative;
            top: -3px;
        }

        &__content {
            margin: 0;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    // Tools
    .tools {
        &__container {
            top: -6px;
        }

        &__item {
            margin: 0 5px;
        }
    }

    // .tools-view {
    //     &__container {
    //         .profils {
    //             margin-top: 80px;
    //             margin-right: 35px;
    //         }
    //     }
    // }

}
