.cover {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $color-light;

    &__container {
        @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: -200px;
            right: -265px;
            height: 310px;
            width: 200px;
            background: url($urlSpriteImage + 'theme-decos/triangle_top.svg')center no-repeat;
            background-size: contain;
            z-index: 0;
        }
    }

    &__image-wrapper {
        width: 570px;
        height: 270px;
        background-color: $color-bg--image;
        flex: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        width: fit-content;
        max-width: 560px;
    }

    &__title {
        color: $color-main;
        font-size: $font-size--1;
        margin: 0;
    }

    &__lead-wrapper {
        p {
            margin: 15px 0 0;
            font-weight: $font-weight-bold;
            font-size: $font-size--5;
            color: $color-main;
        }
    }

    &__intro {
        color: $color-text;
    }


    &--noimg {
        .cover {
            &__informations {
                max-width: 100%;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

//1200 

@media screen and (max-width: $large) {

    .cover {
        &__container {
            &::before {
                right: -150px;
            }
        }

        &__image-wrapper {
            width: 470px;
            height: 220px;
        }

        &__informations {
            max-width: 450px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__container {
            flex-direction: column;
            gap: 30px;
        }

        &__image-wrapper {
            width: 100%;
            height: 320px;
        }

        &__informations {
            max-width: 100%;
            width: 100%;
        }

        &__title {
            @include font-size(30);
            line-height: toRem(34);
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 175px;
        }
    }

}
