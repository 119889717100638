.nav-main {
    //position: relative;
    z-index: 10;

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: center);
        padding: 0;
        margin: 0;
    }

    // Main menu
    .menu {
        @include flex($direction: row);
        gap: 45px;

        &__item {
            &.menu-item-has-children.js-open-submenu {
                .submenu {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                }

                .menu__link {
                    &:after {
                        width: 25%;
                    }
                }
            }
        }   
        
        &__link {
            position: relative;
            @include flex($alignItems: center);
            padding: 0;
            color: $color-main;
            background-color: $color-bg--transparent;
            text-transform: uppercase;
            font-size: $font-size--5;
            line-height: toRem(20);
            font-weight: $font-weight-bold;
            
    
            &:after {
                content: '';
                @include absolute($bottom: -10px, $right: 0, $left: 0);
                @include size(0, 2px);
                margin: 0 auto;
                background-color: $color-main;
                transition: {
                    property: width;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
    
            &:hover, &:focus {
                &::after {
                    width: 25%;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        @include flex($justifyContent: center);
        position: absolute;
        left: 50%;
        z-index: 1;
        width: 100vw;
        max-width: 1170px;
        margin: 50px auto 0;
        padding: 80px 50px 40px;
        background-color: $color-white;
        box-shadow: 0px 0px 40px 0px rgba(40, 40, 40, 0.20);
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%);
        transition: {
            property: visibility, opacity, pointer-events;
            duration: $duration;
            timing-function: $timing;
        }

        &__title {
            @include absolute($top: 35px, $left: 50px);
            font-size: $font-size--4;
            line-height: toRem(30);
            font-weight: $font-weight-black;
            color: $color-main;
        }

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
            margin: 0;
            padding: 0;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            margin: 0 0 5px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px 20px 10px 0;
            border-bottom: 1px solid $color-light;
            color: $color-text;
            font-weight: $font-weight-medium;

            &::before {
                content: "";
                display: inherit;
                position: absolute;
                bottom: -1px;
                width: 0;
                height: 1px;
                background-color: $color-main;
                transition: width $duration--second $timing;
            }

            &::after {
                content: '';
                background-color: $color-third;
                position: absolute;
                right: 0;
                @include size(9px, 16px);
                clip-path: polygon(0 0, 0% 100%, 100% 50%);
                transition: background-color $duration--second $timing;
            }

            &:hover, &:focus {
                &::before {
                    width: 100%;
                }

                &::after {
                    background-color: $color-main;
                }
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }
        
        &__close {
            @include absolute($top: 35px, $right: 50px);
            margin: 0;
            padding: 0;
            height: fit-content;
            background-color: $color-bg--transparent;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            gap: 10px;

            &__icon {
                display: block;
                @include size(30px);
                margin: 0;
                background-color: $color-light;
                border: $btn-border-width $btn-border-style $color-light;
                border-radius: $border-radius--round;
                fill: $color-dark;
            }

            &__mobile {
                display: none;
            }

            svg {
                transition: {
                    property: background-color, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                .submenu__close__icon {
                    background-color: $color-bg--transparent;
                    border-color: $color-light;
                    fill: $color-dark;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close {
        display: none;
    }

}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        .menu {
            gap: 30px;
        }

        .menu__link {
            
        }

        .submenu {
            max-width: 940px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-open-menu {
        overflow: hidden;

        .tools {
            z-index: 9;
        }
    }    

    .nav-main {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        padding: 10px 0;
        background-color: $color-white;
        box-shadow: 1px 1px 20px 0px rgba(40, 40, 40, 0.10);

        // If menu open
        &.js-open-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-main;
            padding: 40px 0;
            transition: left $duration $timing;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 7px;
            height: 40px;
            margin: 0;
            padding: 0;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            font-size: $font-size--5;
            font-weight: $font-weight-bold; 
            color: $color-dark;
            text-transform: uppercase;
            cursor: pointer;

            &:hover, &:focus {
                color: $color-dark;
            }

            svg {
                @include size(26px);
                fill: $color-dark;
            }
        }

        

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: transparent;
            border: $btn-border-width $btn-border-style $color-white;
            border-radius: $border-radius--round;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-white;
            }

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 100%;
            padding: 0 40px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;
                border-bottom: 1px solid rgb(255 255 255 / 30%);
    
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-white;
                        transition: none;
                    }

                    // If sub menu open
                    &.js-open-submenu {   
                        .submenu {
                            right: 0;
                        }
    
                        .menu__link {
                            background-color: $color-bg--transparent;
                            color: $color-white;
                        }
                    }
                }
    
                &:hover, &:focus {   
                    .menu__link {
                        background-color: $color-bg--transparent;
                        color: $color-white;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 20px 0;
                color: $color-white;
                transition: none;

                &::after {
                    display: none;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 40px 0 60px 0;
            background-color: $color-main;
            transform: none;
            box-shadow: none;
            transition: {
                property: visibility, pointer-events, right;
                duration: $duration;
                timing-function: $timing;
            }
            opacity: 1;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                position: absolute;
                left: 40px;
                top: -10px;
                right: unset;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0;
                padding: 0;
                background-color: transparent;
                border: $btn-border-width $btn-border-style $color-white;
                border-radius: $border-radius--round;
                order: 1;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: $color-dark;
                    }
                }

                &__mobile {
                    display: block;
                    width: 18px;
                    height: 18px;
                    fill: $color-white;
                    transform: rotate(180deg);
                    
                }

                &__icon, span {
                    display: none;
                }
            }


            &__title {
                color: $color-white;
                position: static;
                padding-left: 40px;
                margin-top: 10px;
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0 40px;
                overflow-y: auto;
                order: 2;
            }

            &__item {
                margin: 0 0 10px; 
            }

            &__link {
                color: $color-white;
                border-bottom: 0;

                &::before, &::after {
                    display: none;
                }
            }
        }
    }
}
