.transition-category,
.transition-buttons {
    .card {
        margin: 30px 0;
    }
}


.single-transitions {
    .card {
        .card__category {
            opacity: 0;
        }
    }
    
}