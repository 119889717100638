.footer {
    background-color: $color-light;

    &__container {
        @include flex($wrap: wrap, $alignItems: center, $justifyContent: flex-start);
        position: relative;
        gap: 90px;
        padding: 80px 15px;
        height: 300px;

        &::before {
            content: "";
            position: absolute;
            top: -110px;
            right: -150px;
            width: 200px;
            height: 310px;
            background: url($urlSpriteImage + "theme-decos/triangle_footer.svg") center no-repeat;
            background-size: contain;
            z-index: 0;
        }
    }

    &__infos,
    &__opening-hours {
        @include flex($direction: column, $justifyContent: flex-start, $alignItems: flex-start);
        gap: 10px;
        height: 100%;
        flex: none;
        color: $color-dark;
    }

    &__infos {
        width: 215px;
    }

    &__opening-hours {
        width: 450px;
    }



    &__title {
        color: $color-dark;
        @include font-size(22);
        font-weight: $font-weight-bold;
    }

    &__phone {
        @include flex($alignItems: center);
        gap: 5px;
        color: $color-dark;
        font-size: $font-size--text;
        font-weight: $font-weight-bold;

        svg {
            @include size(26px);
            fill: $color-dark;
            border-radius: $border-radius--round;
            background-color: $color-second;
        }

        &:hover, &:focus {
            color: $color-dark;
            text-decoration: underline;
        }
    }

    &__btns {
        @include flex($direction: column, $alignItems: flex-start, $justifyContent: space-between);
        gap: 15px;
        height: fit-content;
        width: 216px;
    }

    &__btn {
        color: $color-white;
        border-color: $color-fourth;
        width: 100%;
        margin: 0;

        svg {
            @include size(20px);
        }

        &:hover, &:focus {
            color: $color-text;
            border-color: $color-fourth;
        }

        &--second {
            background-color: $color-main;
            border-color: $color-main;
            width: 100%;
            margin: 0;

            svg {
                @include size(20px);
            }

            &:hover, &:focus {
                border-color: $color-main;
            }
        }
    }

    &__menu {
        padding: 0 0 50px;
        background-color: $color-light;

        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
            width: 100%;
        }

        &__item {
            position: relative;
            padding: 0;
        }

        &__link {
            color: $color-text;
            font-size: $font-size--text-small;
            line-height: toRem(20);

            &:hover, &:focus {
                color: $color-text;
                text-decoration: underline;
            }
        } 
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .footer {
        &__container {
            gap: 110px;
            justify-content: space-between;
            padding: 80px 10px;

            &::before {
                @include size(130px,200px);
                right: -95px;
                top: -71px;
            }
        }

        &__opening-hours {
            width: 290px;
        }

        &__btns {
            width: 215px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .footer {
        &__container {
            gap: 40px;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            padding-bottom: 60px;
            height: fit-content;

            &::before {
                right: -65px;
            }
        }

        &__infos {
            max-width: 300px;
            width: 100%;
            align-items: center;
        }

        &__opening-hours {
            width: 100%;
            align-items: center;
        }

        &__text {
            text-align: center;
        }

        &__menu {
            padding-bottom: 110px;

            &__list {
                justify-content: center;
                gap: 20px 40px;
            }

            &__item {
                position: relative;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .footer {
        &__infos,
        &__opening-hours {
            flex: none;
            width: 100%;
        }
    }

}
