.scrollTopBtn {
    @include fixed($right: 20px, $bottom: 35px);
    z-index: 100;
    @include size(45px);
    @include flex($alignItems: center, $justifyContent: center);
    padding: 0;
    margin: 0;
    background-color: $color-main;
    border-color: $color-main;
    border-radius: $border-radius--round;
    opacity: 0;
    transform: translateY(100px);
    transition: {
        property: opacity, transform, background-color, border-color;
        duration: .5s, .5s, $duration, $duration;
        timing-function: $timing;
    }

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
    }

    svg {
        @include size(20px);
        fill: $btn-color;
        transition: fill $duration $timing;
        transform: rotate(-90deg);
    }

    &:hover,
    &:focus {
        background-color: $btn-bg--hover;
        border-color: $color-main;

        svg {
            fill: $btn-color--hover;
        }
    }
}