.front-page {
    padding-bottom: 100px;
}

.home {

    &__title {
        @include flex($direction: column, $alignItems: center);
        padding: 0;
        margin: 0;
        width: fit-content;
        font-size: $font-size--1;
        line-height: 1.1;
        font-weight: $font-weight-bold;

        span {
            margin-top: -5px;
            font-weight: $font-weight-light;
            padding-left: 150px;
        }

        &::before {
            bottom: 25px;
            width: 130px;
        }

        &--left {
            @include flex($alignItems: flex-start);
            padding-left: 40px;

            span {
                padding-left: 0;
                padding-right: 150px;
                margin-left: -40px;
            }

            &::before {
                right: 0;
                left: unset;
            }
        }
    }

    &__navigation {
        @include flex($direction: row);
        gap: 10px;
        width: fit-content;
        margin-left: auto;

        button {
            @include size(40px);
            @include flex($alignItems: center, $justifyContent: center);
            position: relative;
            border-radius: $border-radius--round;
            background-color: $color-main;
            border: 1px $border-style $color-main;

            //cancel swiper's default style
            margin: 0;
            padding: 0;
            left: unset;
            right: unset;
            
            &::after {
                display: none;
            }

            svg {
                @include size(40px);
                fill: $color-white;
            }

            &:hover, &:focus {
                background-color: $color-bg--transparent;
                border-color: $color-main;

                svg {
                    fill: $color-dark;
                }
            }
        }
    }

    &__button {
        padding: 15px 30px;
        margin: 0;
        height: 50px;
        width: fit-content;
        @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
        background-color: $color-main;
        border-color: $color-main;
        color: $color-white;
        font-size: $font-size--text-small;
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        &:hover, &:focus {
            background-color: $color-bg--transparent;
            border-color: $color-main;
        }
    }

    //changes to the header on the homepage
    .header {
        margin-bottom: -120px;
        z-index: 12;

        &__container {
            &::before {
                background-color: $color-white;
            }
        }

        .identity {
            filter: drop-shadow(0px 0px 20px #00000080);
            &__title {
                svg {
                    path {
                        fill: $color-white;
                    }                    
                }
            }            
        }

        .menu {
            &__link {
                color: $color-white;
                text-shadow: 0px 0px 20px #00000080;

                &::after {
                    background-color: $color-white;
                }
            }
        }

        .tools {
            filter: drop-shadow(0px 0px 20px #00000080);
            &__item {
                &:hover, &:focus {
                    svg {
                        fill: $color-white;
                    }
                }
            }
        }
        
        &--fixed {

            .header__container {
                &::before {
                    background-color: $color-main;
                }
            }
    
            .identity {
                filter: none;
                &__title {
                    svg {
                        path {
                            fill: $color-main;
                        }                    
                    }
                }            
            }
    
            .menu {
                &__link {
                    color: $color-main;
                    text-shadow: none;
    
                    &::after {
                        background-color: $color-main;
                    }
                }
            }
    
            .tools {
                filter: none;
                &__item {
                    &:hover, &:focus {
                        svg {
                            fill: $color-main;
                        }
                    }
                }
            }
        }
    }

    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {
        position: relative;

        > .container {
            position: relative;
        }

        .container {
            &--content {
                position: relative;
                height: 100%;
                z-index: 3;
            }

            &--navigation, &--pagination {
                @include absolute(0, 0, 0, 0);
                height: 100%;
                pointer-events: none;
            }
        }

        .slideshow {
            @include size(100%, 600px);
            overflow: visible;

            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                @include size(100%);
            }

            &__container {
                display: inherit;
                position: relative;
                @include size(100%);
                
                &::before {
                    content: '';
                    display: block;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 50%) ;
                    @include size(100%);
                    z-index: 2;
                    @include absolute($top: 0, $left: 0, $right: 0, $bottom: 0);
                }
            }

            &__content {
                @include flex($direction: column, $justifyContent: flex-end);
                box-sizing: border-box;
                @include absolute($left: 30px, $bottom: -30px);
                @include size(500px, auto);
                padding: 30px;
                background-color: $color-white;
                color: $color-dark;
            }

            &__title {
                font-size: $font-size--3;
                line-height: toRem(29);
                font-weight: $font-weight-bold;
                color: $color-main;
                position: relative;

                &::before {
                    content: "";
                    @include absolute($bottom: -9px, $left: 0);
                    z-index: 0;
                    @include size(40px, 2px);
                    background-color: $color-main;
                }
            }

            &__description {
                margin-top: 20px;
            }

            &--video {
                img {
                    display: none;
                }
            }

            // Pagination (bullet)
        .c-pagination {
            @include absolute($bottom: -12px, $right: 15px);
            @include flex($alignItems: center, $justifyContent: flex-end);
            gap: 10px;
            height: 25px;
            padding: 6px 10px;
            background-color: $color-main;
            border-radius: 50px;
            z-index: 2;
            pointer-events: auto;

            .nav-swiper-autoplay {
                @include size(12px);
                padding: 0;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                margin: 0;

                &__icon {
                    @include size(12px);
                    fill: $color-white;
                }

                &:hover,
                &:focus {
                    .nav-swiper-autoplay__icon {
                        fill: $color-white;
                    }
                }
            }

            &__bullets {
                @include flex($alignItems: center);
                gap: 10px;

                .swiper-pagination-bullet {
                    @include size(12px);
                    margin: 0 !important;
                    opacity: 1 !important;
                    background-color: $color-white;
                    border: 1px solid $color-white;
                    border-radius: 30px;
                    transition: {
                        property: width, background-color, border-color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover,
                    &:focus,
                    &-active {                    
                        background-color: $color-bg--transparent;
                        border-color: $color-white;
                    }
                }
            }
        }

        .nav-swiper-autoplay {
            &--pause {
                .nav-swiper-autoplay__icon {
                    &--pause {
                        display: none;
                    }
                    &--play {
                        display: block;
                    }
                }
            }
            
            &--play {
                .nav-swiper-autoplay__icon {
                    &--play {
                        display: none;
                    }
                    &--pause {
                        display: block;
                    }
                }
            }
        }
        
        }
    }

    //======================================================================================================
    // Access
    //======================================================================================================
    .access {
        background-color: $color-light;
        padding: 75px 0;

        &__container {
            @include flex($direction: row, $alignItems: flex-start, $justifyContent: space-between);
            position: relative;

            &::before {
                content: "";
                background-color: $color-white;
                @include size(200px, 349px);
                z-index: 2;
                @include absolute($bottom: -274px, $left: -166px);
                background: url($urlSpriteImage + "theme-decos/triangles_actus.svg") center no-repeat;
                background-size: contain;
            }
        }

        &__search {
            width: 50%;
            padding-right: 105px;
        }

        &__title {
            font-size: $font-size--2;
            line-height: 1.6;
            font-weight: $font-weight-light;
            padding: 0;
            margin: 20px 0 10px;

            span {
                font-weight: $font-weight-black;
            }

            &::before {
                display: none;
            }
        }

        .search-container {
            z-index: 5;

            input {
                background: $color-white;
                color: $color-text;
                border-radius: 0;
                border: 1px $border-style $color-white;
                padding: 0 15px;
                height: 60px;
                z-index: 2;
                appearance: none;

                &:focus {
                    background-color: $color-white;
                    border-color: $color-third;
                    
                }

                &:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-search-cancel-button {
                    opacity: 0;
                }

            }

            &-open {

                input {
                    background-color: $color-white;
                    border-color: $color-third;
                    border-bottom: 0;
                }
            }

            .search-svg {
                z-index: 3;
                width: auto;
                height: 36px;
                width: 36px;
                background-color: $color-fourth;
                border: 1px $border-style $color-fourth;
                border-radius: $border-radius--round;

                svg {
                    width: 100%;
                    height: 100%;
                    fill: $color-white;
                }

                &:hover, &:focus {
                    background-color: $color-white;
                    border-color: $color-fourth;

                    svg {
                        fill: $color-dark;
                    }
                }
            }
        }

        .searchResult {
            position: absolute;
            background: $color-white;
            top: 25px;
            padding-top: 25px;
            width: 100%;
            border-radius: 0;
            overflow: hidden;
            box-shadow: $shadow;
            z-index: 1;
            border: 1px $border-style $color-third;

            li {
                background-color: $color-bg--transparent;
                border-color: $color-light;
                display: none;

                &:first-child {
                    border-top: 1px solid $color-gray;
                }

                a {
                    padding: 15px 110px 15px 24px;
                }

                &:nth-child(1), &:nth-child(2), &.show-all {
                    display: block;
                }

            }
        }

        &__list {
            width: 50%;
            @include flex($direction: row, $alignItems: flex-start, $justifyContent: space-between);
        }

        &__item {
            width: calc(33% - (40px / 3));
            height: 170px;
            border: 1px $border-style $color-third;
            padding: 20px 25px 25px;
            position: relative;
            @include flex($direction: column, $alignItems: center, $justifyContent: center);
            gap: 5px;
            transition: {
                property: border-color;
                duration: $duration--second;
                timing-function: $timing;
            }

            p {
                @include flex($direction: column);
                font-size: $font-size--5;
                line-height: toRem(21);
                font-weight: $font-weight-normal;
                text-align: center;
    
                span {
                    font-weight: $font-weight-black;
                }
            }
            

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: calc(50%);
                transform: translateX(-50%);
                width: 30px;
                height: 16px;
                background-color: $color-third;
                clip-path: polygon(50% 100%, 0 0, 100% 0);
                transition: {
                    property: background-color, width, height;
                    duration: $duration--second;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                border-color: $color-main;

                &::before {
                    background-color: $color-main;
                    width: 38px;
                    height: 20px;
                }
            }

            &:nth-child(2) {
                margin-top: 20px;
            }

            &--ext {
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 19px;
                    left: calc(50% - 8px);
                    @include size(16px);
                    background: url($urlSpriteImage + "theme-icons/ar_exit.svg") center no-repeat;
                    background-size: contain;
                }
            }

            svg {
                @include size(45px);
                fill: $color-main;
            }
        }
    }


    //======================================================================================================
    // News 
    //======================================================================================================
    .news {
        margin-top: 120px;

        &__container {
            @include flex($direction: column);
        }

        &__title {
            margin-bottom: -40px;
        }

        &__swiper {
            margin-top: 30px;
            overflow: visible;
            width: 100%;
        }

        &__item {

        }

        &__image {
            @include size(100%, 250px);
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                @include size(55px);
                background-color: $color-white;
                transition: {
                    property: background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
                clip-path: polygon(100% 0, 0% 100%, 100% 100%);
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: 5px;
                right: 5px;
                @include size(20px);
                background: url($urlSpriteImage + "theme-icons/ic_fleche.svg") center no-repeat;      
                background-size: contain; 
                z-index: 2;         
            }

            img {
                @include size(100%);
                object-fit: cover;
            }

            &--notimg {
                background: url($urlShort + '/posts-icons/post.svg') no-repeat $color-bg--image;
                background-position: center;
            }
        }

        &__link {
            @include flex($direction: column);
            gap: 20px;

            &:hover, &:focus {
                .news__image {
                    &::before {
                        background-color: $color-second;
                    }
                }

                .news__infos__title {
                    &::before {
                        width: 80px;
                    }
                }
            }
        }

        &__infos {
            &__title {
                margin: 0;
                padding: 0 0 10px;
                font-size: toRem(25);
                line-height: 1.2;
                font-weight: $font-weight-black;
                color: $color-main;
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 40px;
                    height: 2px;
                    background-color: $color-main;
                    transition: {
                        property: width;
                        duration: $duration--second;
                        timing-function: $timing;
                    }
                }
            }

            &__intro {
                margin-top: 10px;
                color: $color-main;
                font-size: toRem(18);
                font-weight: $font-weight-normal;
                line-height: toRem(25);
            }
        }

        &__button {
            margin: 55px 0 0 auto;
        }
    }

    //======================================================================================================
    // Events
    //======================================================================================================
    .events {
        position: relative;
        margin-top: 75px;
        padding-top: 65px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: calc(50% + 120px);
            width: 100vw;
            height: 305px;
            background-color: $color-light;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: -74px;
            right: calc(50% - 40px);
            width: 210px;
            height: 264px;
            background: url($urlSpriteImage + "theme-decos/triangle_agenda.svg") center no-repeat;
            background-size: contain;
        }

        &__title {
            padding: 0;
            margin-bottom: -40px;

            span {
                margin-left: 64px;
            }
        }

        &__swiper {
            margin-top: 5px;
            height: 325px;
            overflow: visible;
        }

        &__item { 
            transition: {
                property: margin;
                duration: $duration--second;
                timing-function: $timing;
            }

            &.swiper-slide-active, &.swiper-slide-next + .events__item {
                margin-top: 25px;
            }

            &:hover, &:focus {
                .events__info-title {
                    &::before {
                        width: 80px;
                    }
                }

                .events__date {
                    background-color: $color-main;

                    p {
                        color: $color-white;
                    }

                    &--double {
                        &::after {
                            height: 20px;
                            background-color: $color-white;
                        }
                    }
                }
            }
        }
        

        &__image {
            @include size(100%, 180px);

            img {
                @include size(100%);
                object-fit: cover;
            }

            &--notimg {
                background: url($urlSpriteImage + '/events-icons/events.svg') no-repeat $color-bg--image;
                background-position: center;
            }
        }

        &__date {
            margin: -50px 0 0 10px;
            background-color: $color-second;
            @include flex($direction: row);
            z-index: 4;
            width: fit-content;
            position: relative;
            transition: {
                property: background-color;
                duration: $duration--second;
                timing-function: $timing;
            }

            p {
                @include size(60px);
                padding: 10px 15px;
                @include flex($direction: column, $alignItems: center, $justifyContent: space-between);
                font-size: $font-size--text;
                line-height: toRem(18);
                font-weight: $font-weight-normal;
                text-transform: uppercase;
                box-sizing: border-box;
                transition: {
                    property: color;
                    duration: $duration--second;
                    timing-function: $timing;
                }
            }

            &--double {
                position: relative;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: -1px;
                    margin: auto 0;
                    width: 2px;
                    height: 40px;
                    background-color: $color-dark;
                    transition: {
                        property: height, background-color;
                        duration: $duration--second;
                        timing-function: $timing;
                    }
                }
            }

            &__number {
                font-size: toRem(25);
                line-height: 0.8;
                font-weight: $font-weight-black;
            }
        }

        &__info-title {
            margin: 10px 0 0 10px;
            padding-bottom: 10px;
            font-size: toRem(25);
            line-height: 1.2;
            font-weight: $font-weight-black;
            color: $color-main;
            position: relative;
            width: 295px;

            &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40px;
                height: 2px;
                background-color: $color-main;
                transition: {
                    property: width;
                    duration: $duration--second;
                    timing-function: $timing;
                }
            }
        }

        &__buttons {
            width: fit-content;
            margin: 20px 0 0 auto;
            @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
            gap: 15px;
        }

        &__button {
            &--second {
                margin: 0;
                height: 50px;
            }
        }
    }

    //======================================================================================================
    // Reseaux
    //======================================================================================================
    .socials {
        margin-top: 80px;

        &__container {
            @include flex($direction: column, $alignItems: flex-start, $justifyContent: space-between);
            gap: 30px;
        }

        &__top  {
            @include flex($direction: row, $alignItems: flex-end, $justifyContent: flex-start);
            gap: 25px;
        }

        &__reseaux {
            @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
            gap: 10px;
            width: fit-content;
            margin-bottom: 5px;
        }

        &__reseau {
            display: block;
            @include size(40px);
            background-color: $color-main;
            border-radius: $border-radius--round;
            border: $border-style 1px $color-main;
            transition: {
                property: background-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus {
                background-color: $color-bg--transparent;

                svg {
                    fill: $color-dark;
                }
            }

            svg {
                fill: $color-white;
                width: 100%;
                height: 100%;
                transition: {
                    property: fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
        }

        &__content {
            width: 100%;
            height: 420px;
            @include flex($direction: row, $justifyContent: space-between);
        }

        &__sw {
            width: 760px;
            margin-left: -30px;

            // !important to override flow-flow's style
            .ff-stream {
                height: 100% !important;
                min-height: 100% !important;
                padding: 0 !important;
                background-color: $color-bg--transparent !important;
                
                .ff-stream-wrapper {
                    margin-top: 0 !important;
                    height: 100% !important;

                    .ff-item {
                        height: 100% !important;

                        &.ff-no-image {
                            .ff-item-cont {
                                padding-top: 190px !important;
                            }
                        }
                        
                        .picture-item__inner {
                            box-shadow: none !important;
                            background-color: $color-light !important;
                        }

                        .ff-item-cont {
                            height: 380px!important;
                            overflow: hidden !important;
    
                            .ff-img-holder {
                                @include size(100%!important, 190px!important);
                                overflow: hidden;
    
                                img {                        
                                    @include size(100%!important, 190px!important);
                                    min-height: unset!important;
                                    object-fit: cover!important;
                                }
                            }
    
                            .ff-content {
                                @include size(230px!important, 165px!important);
                                margin: 15px 20px 10px!important;
                                color: $color-text!important;
                                position: relative !important;
    
                                br {
                                    content: "";
                                    margin: 0.5em !important;
                                    font-size: 8px;
                                }
                            }
                        }
    
                        .ff-item-bar {
                            padding: 12px 15px!important;
                            border-color: $color-white !important;
                        }
                    }
                }

                .ff-loadmore-wrapper {
                    display: none !important;
                }
            }
        }

        &__infos {
            width: 440px;
            padding: 40px 50px;
            background-color: $color-third;
            @include flex($direction: column, $alignItems: center, $justifyContent: flex-start);
            gap: 30px;
        }

        &__infos-title {
            font-size: toRem(32);
            line-height: 1.25;
            font-weight: $font-weight-bold;
            text-align: center;
            position: relative;
            padding-bottom: 10px;
            margin: 0;

            &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 40px;
                height: 2px;
                background-color: $color-dark;
            }
        }

        &__infos-intro {
            margin-top: 10px;
            text-align: center;

            &--bold {
                font-weight: $font-weight-bold;
            }
        }

        &__btns {
            @include flex($direction: column, $alignItems: center, $justifyContent: space-between);
            gap: 10px;
            @include size(100%, fit-content);
        }

        &__link {
            width: 100%;
            padding: 10px 0;
            @include flex($direction: row, $alignItems: center, $justifyContent: center);
            gap: 10px;
            font-size: $font-size--text-small;
            line-height: toRem(20);
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            border: $border-style 1px $color-white;
            transition: {
                property: background-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus {
                background-color: $color-white;
            }

            svg {
                @include size(30px);
                fill: $color-dark;
            }
        }
    }

    //======================================================================================================
    // plateforme 
    //======================================================================================================
    .platform {
        padding: 45px 0 55px;
        background-color: $color-light;
        margin-top: 120px;

        &__container {
            @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
            gap: 55px;
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: -215px;
                right: -180px;
                width: 200px;
                height: 310px;
                background: url($urlSpriteImage + "theme-decos/triangle_top.svg") center no-repeat;
                background-size: contain;
                z-index: 1;
            }
        }

        &__text {
            width: 425px;
        }

        &__intro {
            font-size: $font-size--text;
            margin-top: 14px;

            &--bold {
                font-weight: $font-weight-black;
            }
        }

        svg {
            @include size(320px, 200px);
        }

        &__btn {
            @include size(315px, 190px);
            padding: 20px 30px 30px 35px;
            @include flex($direction: column, $alignItems: flex-start, $justifyContent: space-between);
            border: $border-style 1px $color-third;
            position: relative;
            transition: {
                property: border-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus {
                border-color: $color-main;

                &::before {
                    background-color: $color-main;
                }
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: 40px;
                left: 0;
                @include size(16px, 30px);
                background-color: $color-third;
                clip-path: polygon(0 0, 0% 100%, 100% 50%);
                z-index: 2;
                transition: {
                    property: background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            svg {
                @include size(60px, 55px);
                margin-top: 10px;
                margin-left: auto;
                fill: $color-main;
            }

            p {
                @include flex($direction: column);
                font-size: toRem(25);
                line-height: toRem(25);
                font-weight: $font-weight-light;

                span {
                    font-weight: $font-weight-black;
                }

                svg {
                    fill: $color-dark;
                    @include size(16px);
                }
            }
        }
    }

    //======================================================================================================
    // admin-area
    //======================================================================================================
    .admin-area {
        margin-top: 120px;

        &__container {
            @include flex($direction: column);
        }

        &__title {
            margin-bottom: -55px;
        }

        &__content {
            @include flex($direction: column, $wrap: wrap);
            gap: 30px;
            height: 590px;
            column-count: 3;
        }

        &__image {
            @include size(435px, 225px);
            position: relative;
            overflow: hidden;

            img {
                @include size(100%);
                object-fit: cover;
                transition: {
                    property: transform;
                    duration: $duration--second;
                    timing-function: $timing;
                }
            }

            &:nth-child(1) {
                @include size(370px, 225px);
                margin-left: 65px;
                margin-top: 100px;
            }

            &:nth-child(3) {
                @include size(370px, 240px);

            }

            &:nth-child(4) {
                @include size(335px, 240px);
                margin-bottom: 80px;
            }

            &:nth-child(5) {
                @include size(305px, 255px);
                margin-top: 50px;
            }

            &:nth-child(6) {
                @include size(340px, 255px);
                margin-left: -35px;
            }

            &--video {

            }

            &--hover {
                &:hover, &:focus {
                    img {
                        transform: scale(1.05);
                    }

                    .admin-area__img-title {
                        opacity: 1;
                    }
                }
            }           
        }

        &__img-title {
            @include absolute($top: 0, $left: 0, $right: 0, $bottom: 0);
            @include size(100%);
            background-color: #00000080;
            @include flex($direction: column, $alignItems: center, $justifyContent: center);
            gap: 15px;
            opacity: 0;
            transition: {
                property: opacity;
                duration: $duration--second;
                timing-function: $timing;
            }
            
            h3 {
                font-size: toRem(18);
                line-height: toRem(25);
                font-weight: $font-weight-bold;
                color: $color-white;
                width: 150px;
                text-align: center;
                margin: 0;
            }

            svg {
                fill: $color-white;
                @include size(25px);
                border: $border-style 1px $color-white;
                border-radius: $border-radius--round
            }
        }

        &__video-img {
            @include size(100%);
            position: relative;

            svg {
                @include absolute($top: 50%, $left: 50%);
                transform: translate(-50%, -50%);
                @include size(40px);
                fill: $color-dark;
                background-color: $color-second;
                border: 1px $border-style $color-second;
                border-radius: $border-radius--round;
                transition: {
                    property: background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                svg {
                    background-color: $color-white;
                }
            }

            img {
                @include size(100%);
                object-fit: cover;
            }
        }

        //demosaisie style for video popup
        .popup-area {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 1000;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            background: $color-black--rgba;

            &.show-popup {
                visibility: visible;
                opacity: 1;
            }

            video,
            iframe {
                max-width: 100%;
                max-height: 100%;
            }

            .accordion {
                height: 0;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }

            .close-area-popup {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 60px;
                right: calc((100% - 1170px)/2);
                background: $color-light;
                border-radius: 50%;
                transition: $duration;

                &::before {
                    content: '\f335';
                    font: normal 30px/1 'dashicons';
                    color: $color-main;
                    transition: $duration;
                }

                &:hover {
                    cursor: pointer;
                    background-color: $color-main;

                    &::before {
                        color: $color-white;
                    }
                }
            }
        }


        &__button {
            width: 190px;
            height: 50px;
            margin: -50px auto 0 531px;
        }
    }


    //======================================================================================================
    // Kiosque
    //======================================================================================================
    .kiosque {
        margin-top: 120px;
        position: relative;

        &::before {
            content: "";
            display: block;
            @include size(100vw, 332px);
            position: absolute;
            top: 0;
            right: calc(50% + 406px);
            background-color: $color-light;
            z-index: -1;
        }

        &::after {
            content: "";
            display: block;
            @include size(100vw, 220px);
            position: absolute;
            top: 110px;
            left: calc(50% + 170px);
            background-color: $color-light;
            z-index: -1;
        }

        &__container {
            @include flex($direction: row, $wrap: wrap, $justifyContent: space-between);
            padding-top: 47px;
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 231px;
                right: calc(50% + 434px);
                @include size(199px, 200px);
                background: url($urlSpriteImage + "theme-decos/triangles_kiosque.svg") center no-repeat;
                background-size: contain;
                z-index: 1;
            }
        }

        &__title {
            margin: 0 auto -150px 276px;
            height: fit-content;
        }

        &__magazines {
            @include flex($direction: row, $wrap: wrap, $alignItems: flex-start, $justifyContent: space-between);
            gap: 40px;
            width: fit-content;
        }

        &__navigation {
            flex-direction: column;
            margin-top: 100px;
        }

        &__swiper {
            width: 550px;
            padding: 15px 0 15px 15px;
            margin: -15px 0 -15px -15px;
        }

        &__slide {
            @include flex($direction: row, $alignItems: flex-end, $justifyContent: flex-start);
            gap: 40px;
        }

        &__image {
            @include size(260px, 365px);
            flex: none;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);

            img {
                @include size(100%);
                object-fit: cover;
            }

            &--no-image {
                background: url($urlShort + '/documents-icons/documents.svg') no-repeat $color-bg--image;
                background-position: center;
            }
        }

        &__content {
            @include flex($direction: column, $alignItems: flex-start, $justifyContent: space-between);
            gap: 20px;
            background-color: $color-white;
            margin-bottom: 95px;

            &__title {
                font-size: toRem(25);
                line-height: toRem(30);
                font-weight: $font-weight-black;
                margin: 0;
            }

            &__buttons {
                @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
                gap: 10px;
                width: fit-content;

                .btn {
                    @include size(40px);
                    border-radius: $border-radius--round;
                    background-color: $color-second;
                    @include flex($alignItems: center, $justifyContent: center);
                    transition: {
                        property: background-color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover, &:focus {
                        background-color: $color-main;
                        svg {
                            fill: $color-white;
                        }
                    }

                    svg {
                        fill: $color-dark;
                        @include size(30px);
                        transition: {
                            property: fill;
                            duration: $duration;
                            timing-function: $timing;
                        }
                    }
                }
            }
        }

        &__docs {
            @include flex($direction: row, $alignItems: flex-start, $justifyContent: space-between);
            gap: 40px;
            width: fit-content;
            margin-top: 48px;
        }
        
        &__document {
            @include flex($direction: column, $alignItems: center, $justifyContent: space-between);
            gap: 10px;
            width: 150px;

            &:last-child {
                margin-top: 50px
            }

            .kiosque {
                &__image {
                    @include size(150px, 210px);
                    margin-top: 5px;
                }

                &__content__title {
                    font-size: toRem(18);
                    line-height: toRem(20);
                    font-weight: $font-weight-bold;
                    text-align: center;
                }

                &__content__buttons {
                    gap: 5px;
                }
            }
        }

        &__btn {
            margin: -99px 0 0 380px;
            z-index: 4;
        }
    }

    //======================================================================================================
    // Responsive
    //======================================================================================================

    // 1200
    @media screen and (max-width: $large) {


        .home {
            &__title {
                font-size: toRem(38);
                line-height: toRem(40);
                align-items: flex-start;

                &::before {
                    width: 70px;
                    bottom: 15px;
                }

                span {
                    padding-left: 90px;
                }

                &--left {


                    span {
                        padding: 0 78px 0 0;
                    }
                }
            }
        }

        .home-slideshow {
            .slideshow {
                height: 480px;
            }
        }

        .access {
            &__container {
                &::before {
                    @include size(130px, 226px);
                    left: -120px;
                    bottom: -204px;
                }
            }

            &__search {
                padding-right: 50px;
            }
        }
        
        .news {
            &__image {
                height: 210px;
            }

            &__button {
                margin-top: 40px;
            }
        }

        .events {
            &::before {
                height: 275px;
                right: calc(50% + 70px);
            }

            &::after {
                top: -48px;
                right: calc(50% - 33px);
                @include size(135px, 170px);
            }

            &__swiper {
                height: 305px;
            }

            &__image {
                @include size(100%, 150px);
            }
        }

        .socials {
            &__sw {
                width: 620px;
                margin-left: -20px;

            }

            &__infos {
                width: 390px;
                padding: 35px 30px;
            }
        }

        .platform {
            &__container {
                gap: 30px;
            }

            &__text {
                width: 320px;
            }

            &__intro {
                margin-top: 15px;
            }

            svg {
                flex: none;
            }

            &__btn {
                width: 220px;
            }
        }

        .admin-area {

            &__title {
                margin-bottom: -20px;
            }

            &__content {
                gap: 20px;
                height: 465px;  
            }
            
            &__image {
                @include size(370px, 190px);

                &:nth-child(1) {
                    @include size(305px, 185px);
                    margin-left: 65px;
                    margin-top: 60px;
                }
                
                &:nth-child(3) {
                    @include size(285px, 185px);
                }
                
                &:nth-child(4) {
                    @include size(250px, 180px);
                    margin-bottom: 50px;
                }
                
                &:nth-child(5) {
                    @include size(245px, 205px);
                    margin-top: 30px;
                }
                
                &:nth-child(6) {
                    @include size(280px, 210px);
                    margin-left: -35px;
                }
            }

            &__button {
                margin-left: 420px;
            }
        }

        .kiosque {
            &::before {
                height: 332px;
                right: calc(50% + 291px);
            }

            &::after {
                height: 180px;
                top: 100px;
                left: calc(50% + 125px);
            }

            &__container {

            }

            &__title {
                margin-left: 301px;

                &::before {
                    left: -50px;
                }

                span {
                    margin-left: -60px;
                }
            }

            &__magazines {
                gap: 20px;
            }

            &__swiper {
                width: 455px;
            }

            &__slide {
                gap: 25px;
            }

            &__image {
                @include size(215px, 300px);
            }

            &__content {
                margin-bottom: 70px;
            }


            &__docs {
                margin-top: 35px;
                width: 300px;
            }

            &__document {
                width: 150px;

                .kiosque {
                    &__image {
                        @include size(120px, 170px);
                    }
                }
            }

            &__btn {
                margin: -110px 0 0 305px;
            }
        }
    }


    // 960
    @media screen and (max-width: $medium) {

        .header {
            .tools {
                filter: none;
            }
        }

        .home-slideshow {
            position: relative;

            .slideshow {
                height: 320px;

                &--video {
                    video {
                        display: none;
                    }

                    img {
                        display: block;
                    }
                }


                &__content {
                    bottom: unset;
                    top: 300px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .c-pagination {
                    bottom: 30px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: fit-content;
                }
            }
        }

        .access {
            padding: 200px 0 100px;

            &--no-padding-mobile {
                padding-top: 30px;
            }

            &__container {
                flex-direction: column;
                align-items: center;
                gap: 35px;

                &::before {
                    @include size(130px, 226px);
                    left: -50px;
                    bottom: -229px;
                }
            }

            &__search {
                padding-right: 0;
                width: 420px;
            }

            &__list {
                width: 100%;
            }
        }

        .news {
            margin-top: 100px;

            &__image {
                height: 155px;
            }
        }

        .events {
            margin-top: 55px;
            
            &::before {
                right: calc(50% - 50px);
            }

            &::after {
                right: calc(50% - 152px);
            }

            &__swiper {
                margin-top: 30px;
                height: fit-content;
            }

            &__item {
                &.swiper-slide-active, &.swiper-slide-next + .events__item {
                    margin-top: 0;
                }
            }

            &__image {
                @include size(100%, 170px);
            }

            &__buttons {
                margin-top: 60px;
            }
        }

        .socials {
            &__content {
                height: fit-content;
                flex-direction: column;
            }

            &__sw {
                width: calc(100% + 60px);
                height: 425px;
                margin-left: -30px;
                margin-right: -30px;
            }

            &__infos {
                width: 100vw;
                margin: 30px calc(-5% - 15px) 0;
                padding: calc(5% + 15px);
                flex-direction: row;
                gap: 30px;
            }

            &__text, &__btns {
                width: calc(50% - 15px);
            }
        }

        .platform {
            &__container {
                gap: 55px 0;
                flex-wrap: wrap;
            }

            &__text {
                width: calc(50% - 10px);
            }

            svg {
                @include size(240px, 150px);
                margin: 40px auto 0;
            }

            &__btn {
                width: 100%;

                svg {
                    @include size(60px, 55px);
                    margin: 10px 0 0 auto;
                }
            }
        }


        .admin-area {
            &__title {
                margin-bottom: -100px;
            }

            &__content {
                column-count: 2;
                height: 830px;
            }

            &__image {
                @include size(calc(50% - 10px), 175px);

                &:nth-child(1) {
                    @include size(calc(50% - 10px), 205px);
                    margin-left: 0;
                    margin-top: 140px;
                }
                
                &:nth-child(3) {
                    @include size(calc(50% - 10px), 270px);
                    margin: 0;
                }
                
                &:nth-child(4) {
                    @include size(calc(50% - 10px), 220px);
                    margin: 0;
                }
                
                &:nth-child(5) {
                    @include size(calc(50% - 10px), 245px);
                    margin: 0;
                }
                
                &:nth-child(6) {
                    @include size(calc(50% - 10px), 255px);
                    margin: 0;
                }
            }

            &__button {
                margin-left: calc(50% + 10px);
            }
        }

        .kiosque {
            &::before {
                height: 261px;
                right: calc(50% + 171px);
                top: 135px;
            }

            &::after {
                height: 180px;
                left: calc(50% + 5px);
                top: 200px;
            }

            &__container {
                padding-top: 0;
            }

            &__title {
                margin: 0 calc(100% - 265px) 30px 0;
            }

            &__magazines {
                gap: 40px;
                width: calc(50% - 55px);
                flex-wrap: nowrap;
                height: fit-content;
            }

            &__swiper {
                width: calc(100% - 50px);
                margin: -15px;
                padding: 15px;
            }

            &__navigation {
                margin: 100px 0 0 0;
            }

            &__slide {
                flex-direction: column;      
            }

            &__image {
                width: 100%;
            }

            &__content {
                margin: 0;
            }

            &__docs {
                margin: 75px 0 0 0;
                width: calc(50% - 50px);
                height: fit-content;
                gap: 30px;
            }

            &__document {
                width: calc(50% - 30px);
                margin: 0;

                &:last-child {
                    margin-top: 0;
                }
            }

            &__btn {
                margin: 0;
                @include absolute($top: 105px, $right: 10px);
            }
        }

    }


    // 640
    @media screen and (max-width: $small) {

        .home {
            &__navigation {
                margin: 20px 0 0 0; 
            }
        }


        .home-slideshow {
            .slideshow {
                
                &__content {
                    left: 30px;
                    right: 30px;
                    width: auto;
                }
            }
        }

        .access {
            padding: 210px 0 75px;

            &--no-padding-mobile {
                padding-top: 30px;
            }

            &__container {
                &::before {
                    left: -100px;
                    bottom: -204px;
                }
            }

            &__search {
                width: 100%;
            }

            &__title {
                font-size: $font-size--3;
            }

            &__list {
                flex-direction: column;
                gap: 20px;
            }

            &__item {
                width: 100%;
                @include flex($direction: row, $alignItems: center, $justifyContent: center);
                gap: 15px;
                height: 110px;
                column-count: 2;
                
                &--ext {
                    &::after {
                        position: static;
                        margin: 0 0 -23px -10px;
                    }
                }

                &:nth-child(2) {
                    margin-top: 0;
                }
            }
        }

        .news {
            margin-top: 60px;

            &__title {
                margin-bottom: 0;
            }

            &__image {
                height: 140px;
            }
            
            &__button {
                margin: 40px 0 0;
            }

        }

        .events {
            margin-top: 70px;
            
            &::before {
                right: unset;
                left: 0;
                @include size(300px, 275px);
            }

            &::after {
                right: unset;
                left: 267px;
            }

            &__title {
                margin-bottom: 0;
            }

            &__image {
                @include size(100%, 150px);
            }

            &__buttons {
                margin: 50px 0 0;
                flex-direction: column;
                align-items: flex-start;
            }

            &__button {
                height: 50px;
                padding: 10px 30px;

                &--second {
                    height: 50px;
                    padding: 10px 30px;
                }
            }
        }

        .socials {
            &__top {
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
            }

            &__reseaux {
                margin: 0 0 0 90px;
            }

            &__title {
                margin: 0;
                padding: 0;

                span {
                    margin: 0;
                    padding: 0;
                }

                &::before {
                    bottom: -40px;
                    right: unset;
                    left: 0;
                }
            }

            &__sw {
                height: 760px;

                .ff-stream {
                    
                    .ff-stream-wrapper {
    
                        .ff-item {
                            height: fit-content!important;

                            .ff-item-cont {
                                height: 320px!important;
        
                                .ff-img-holder {
                                    @include size(100%!important, 170px!important);
        
                                    img {                        
                                        @include size(100%!important, 170px!important);
                                    }
                                }
        
                                .ff-content {
                                    @include size(calc(100% - 40px)!important, 140px!important);
                                }
                            }
                        }
                    }
                }
            }

            &__infos {
                flex-direction: column;

            }

            &__text, &__btns {
                width: 100%;
            }
        }

        .platform {
            margin-top: 0;

            &__container {
                gap: 40px;
                flex-direction: column;
                align-items: flex-start;

                > svg {
                    margin: 0;
                }
            }

            &__text {
                width: 100%;
                margin-bottom: 10px;
            }
            
            

            &__btn {
                height: 160px;

                svg {
                    margin: 10px 0 -5px auto;
                }
            }
        }

        .admin-area {
            margin-top: 70px;

            &__title {
                margin-bottom: 0;
            }

            &__content {
                column-count: 1;
                height: fit-content;
            }

            &__image {
                @include size(100%, 155px);

                &:nth-child(1) {
                    @include size(100%, 180px);
                    margin-top: 30px;
                }
                
                &:nth-child(3) {
                    @include size(100%, 240px);
                }
                
                &:nth-child(4) {
                    @include size(100%, 195px);
                }
                
                &:nth-child(5) {
                    @include size(100%, 215px);
                }
                
                &:nth-child(6) {
                    @include size(100%, 225px);
                }
            }

            .popup-area .close-area-popup {
                right: calc(5% + 10px);
            }

            &__button {
                margin: 30px 0 0;
            }
        }

        .kiosque {
            &::before {
                right: calc(5% + 10px);
                top: 125px;
            }

            &::after {
                left: calc(5% + 10px);
                top: 670px;
            }

            &__container {
                flex-direction: column;
                align-items: flex-start;

                &::before {
                    @include size(128px);
                    right: 10px;
                    top: 322px;
                }
            }

            &__title {
                margin: 0 0 30px 0;
            }

            &__magazines {
                width: 100%;
                flex-direction: row-reverse;
                gap: 0;
            }

            &__navigation {
                @include absolute($top: 210px, $left: 250px);
                margin: 0;

            }

            &__swiper {
                width: 100%;
                margin: -15px 0 0 -15px;
                padding: 15px 0 0 15px;
            }

            &__slide {
                align-items: flex-start;
            }

            &__image {
                @include size(215px, 300px);
            }

            &__docs {
                width: 270px;
                margin: 100px auto 0;
            }

            &__btn {
                right: unset;
                left: 10px;
                top: 545px;
            }
        }
    }
}



